/* --------------------------------
   content
-------------------------------- */

.content-container
{
	ol:not([class]),
	ul:not([class])
	{
		line-height: 1.2;
		margin-top: 25px;
		margin-bottom: 25px;

		&:first-child { margin-top: 0 !important; }
		&:last-child  { margin-bottom: 0 !important; }

		li
		{
			margin-top: 5px;
			line-height: 20px;
			padding-left: 15px;

			&:before
			{
				float: left;
				margin-left: -15px;
				margin-right: 5px;
			}

			&:first-child { margin-top: 0; }
		}
	}

	ol:not([class])
	{
		counter-reset: num;

		li
		{
			&:before
			{
				counter-increment: num;
				content: counter(num) ".";
			}
		}
	}

	ul:not([class])
	{
		li
		{
			&:before
			{
				content: "";
				width: 0;
				height: 0;
				margin-top: 6px;
				border: 2px solid #555;
			}
		}
	}

	hr
	{
		margin-top: 60px;
		margin-bottom: 60px;
		border: none;
		border-top: 1px solid #ebebeb;

		&:first-child { margin-top: 0 !important; }
		&:last-child  { margin-bottom: 0 !important; }
	}

	.blockquot, blockquote
	{
		margin-top: 40px;
		margin-bottom: 40px;
		padding-left: 20px !important;
		border-left: 5px solid $primary-color !important;
		line-height: 1.2;
		font-size: rem-calc(20px);
		font-family: 'Times New Roman', Times, serif;
		color: #696248;

		&:first-child { margin-top: 0; }
		&:last-child  { margin-bottom: 0; }

		@include media-breakpoint-up(sm)
		{
			font-size: rem-calc(23px);
		}

		@include media-breakpoint-up(md)
		{
			padding-left: 25px;
			font-size: rem-calc(25px);
		}
	}

	.dropcaps
	{
		.first-letter
		{
			float: left;
			margin-right: 10px;
			line-height: 0.9;
			font-size: rem-calc(46px);
			font-weight: 800;
			color: $primary-color;
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}