/* --------------------------------
   product-preview
-------------------------------- */

$gutter: 10px;

.product-preview {
    width: 100%;

    .__inner {
        margin-bottom: -$gutter;
    }

    .row {
        margin-left: -$gutter/2;
        margin-right: -$gutter/2;
    }

    .col,
    [class*="col-"] {
        padding-left: $gutter/2;
        padding-right: $gutter/2;
    }

    .__item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: $gutter;
        @include transition( padding 500ms );

        .__intro-text {
            margin-left: auto;
            width: 100%;
            max-width: 580px;
        }

        .__image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            overflow: hidden;
            @include transition( top 500ms, right 500ms, bottom 500ms, left 500ms );

            + .__content {
                background-color: rgba(#333, 0.2);
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                @include object-fit(cover, 50% 50%);
                @include transition( transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms );
            }
        }

        .__content {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: #333;
            color: $white-color;
            @include transition(background-color 0.3s ease-in-out);
        }

        .__title {
            color: inherit;

            &--hover {
                opacity: 0;
                @include transition(opacity 0.3s ease-in-out);
            }
        }

        .__paragraph {
            text-align: center;
            padding: 0 15px;
            opacity: 0;
            @include transition(opacity 0.3s ease-in-out);
        }

        .__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:hover {
            .__image + .__content {
                background-color: rgba($primary-color, 0.9);
            }

            .__paragraph {
                opacity: 1;
            }

            .__title {

                &--hover {
                    opacity: 1;
                }
            }
        }
    }
}

.product-preview--style-1 {
    .__item {
        height: 325px;

        .__image {
        }
    }
}

.product-preview--style-2 {
    .__item {
        height: 450px;

        .__image {
        }

        &:hover {
            .__image {
                top: 20px;
                right: 20px;
                bottom: 20px;
                left: 20px;
            }
        }
    }
}

.product-preview--style-3 {
    .__item {
        height: 250px;
    }
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
    .product-preview--style-2 {
        .__item {
            height: 350px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .product-preview--style-2 {
        .__item {
            height: 650px;
        }
    }
}

@include media-breakpoint-up(xl) {
}

.related-title {
    @include media-breakpoint-down(sm) {
        margin-bottom: 20px !important;
    }

    .__title {
        @include media-breakpoint-down(sm) {
            text-align: left !important;
            font-size: 20px;
            font-size: 1.6rem;
        }
    }
}

.related-itens {

    .__inner {
        margin-bottom: -$gutter;
    }

    .row {
        margin-left: -$gutter/2;
        margin-right: -$gutter/2;
    }

    .col,
    [class*="col-"] {
        padding-left: $gutter/2;
        padding-right: $gutter/2;
    }

    .__item {
        position: relative;
        display: flex;
        align-items: center;
        height: 325px;
        width: 100%;
        margin-bottom: $gutter;
        @include transition( padding 500ms );

        @include media-breakpoint-down(sm) {
            height: auto;
            margin-bottom: 20px;
        }

        .__intro-text {
            margin-left: auto;
            width: 100%;
            max-width: 580px;
        }

        .__image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            overflow: hidden;
            @include transition( top 500ms, right 500ms, bottom 500ms, left 500ms );

            @include media-breakpoint-down(sm) {
                position: initial !important;
                float: left;
                margin-right: 15px;
                min-width: 100px;
                height: 75px;
            }

            + .__content {
                background-color: rgba(#333, 0.2);

                @include media-breakpoint-down(sm) {
                    background-color: transparent;
                }
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                @include object-fit(cover, 50% 50%);
                @include transition( transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms );

                @include media-breakpoint-down(sm) {
                    width: 100px;
                    height: 75px;
                    object-fit: cover;
                    object-position: bottom;
                }
            }
        }

        .__content {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: #333;
            color: $white-color;
            @include transition(background-color 0.3s ease-in-out);

            @include media-breakpoint-down(sm) {
                background-color: transparent;
                color: $light-black;
                justify-content: flex-start;
            }
        }

        .__title {
            color: inherit;
            text-align: center;

            @include media-breakpoint-down(sm) {
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 5px;
            }

            a {
                @include media-breakpoint-down(sm) {
                    background-color: transparent;
                    color: #765535;
                    text-decoration: none;
                    outline: 0;
                    -webkit-transition: color .3s ease-in-out;
                    -o-transition: color .3s ease-in-out;
                    transition: color .3s ease-in-out;

                    &:hover {
                        color: #f08012;
                        text-decoration: none;
                    }
                }
            }

            &--hover {
                opacity: 0;
                @include transition(opacity 0.3s ease-in-out);

                @include media-breakpoint-down(sm) {
                    opacity: 1;
                }
            }
        }

        .__paragraph {
            text-align: center;
            padding: 0 15px;
            opacity: 0;
            @include transition(opacity 0.3s ease-in-out);

            @include media-breakpoint-down(sm) {
                opacity: 1;
                padding: 0;
                text-align: left;
                color: $light-black;
                margin-top: 0px;
                max-height: 63px;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 20px;
                overflow: hidden;
                text-transform: none;
                font-size: 12px;
            }
        }

        .__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &:hover {
            .__image + .__content {
                background-color: rgba($primary-color, 0.9);

                @include media-breakpoint-down(sm) {
                    background-color: transparent;
                }
            }

            .__paragraph {
                opacity: 1;
            }

            .__title {

                &--hover {
                    opacity: 1;
                }
            }
        }
    }
}


.related-posts {
    .__inner {
        margin-bottom: -$gutter;
    }

    .__banner {
        width: 100%;
        max-height: 520px;
        height: auto;
        margin-bottom: 30px;
        object-fit: cover;

        @include media-breakpoint-down(sm) {
            max-height: 320px;
        }
    }

    .__item {
        position: relative;
        width: 100%;
        margin-bottom: $gutter;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
            align-items: center;
        }

        .__image {
            position: relative;
            width: 100%;
            height: 0;
            margin: auto;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                height: auto;
                width: 100px;
                height: 75px;
                padding-top: 0 !important;
                float: left;
                z-index: 1;
                margin-right: 15px;
            }

            img,
            .__overlay {
                @extend %block-absolute--full;
            }

            img {
                @include object-fit(cover, 50% 50%);
                @include transition( transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms );

                @include media-breakpoint-down(sm) {
                    width: 100px;
                    height: 75px;
                    object-fit: cover;
                    object-position: bottom;
                }
            }

            .__content {
                background-color: transparent;
                z-index: 2;
            }
        }

        .__content {
            position: relative;
            padding-top: 40px;
            line-height: 1.6;

            @include media-breakpoint-down(sm) {
                text-align: left;
                padding-top: 0;
            }

            img {
                max-width: 100%;
            }
        }

        .__category {
            margin-bottom: 10px;
            line-height: 1.2;
            font-size: rem-calc(12px);
            color: darken(desaturate($primary-color, 29.12), 7.84);

            @include media-breakpoint-down(sm) {
                display: none;
            }

            a {
                color: inherit;
            }
        }

        .__title {
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 700;
            max-height: 63px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: none;
        }

        .__article-title {
            font-size: 24px;
            line-height: 30px;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }
        }

        .custom-btn {
            margin-top: 10px;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .__date-post {
            font-size: rem-calc(12px);
            color: #c1c1c1;
        }

        &:hover {
            .__image img {
                transform: scale(1.2) translateZ(0);
            }
        }
    }

    .__item--preview {
        .__date-post {
            display: inline-block;
            background-color: $primary-color;
            min-width: 60px;
            padding: 13px 10px;
            line-height: 1;
            font-weight: 700;
            text-align: center;
            color: $white-color;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            strong {
                display: block;
                margin-bottom: 2px;
                font-size: rem-calc(25px);
                font-weight: 900;
                font-family: $secondary-fontFamily;
                color: #333;
            }
        }

        p {
            margin-top: 10px;
            margin-bottom: 10px;
            max-height: 63px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                margin-top: 5px;
                margin-bottom: 0;
                -webkit-line-clamp: 2;
                line-height: 20px;
                font-size: 12px;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-feedback {
        margin-top: 60px;

        .comments-list {
        }

        .textfield {
            &:hover,
            &:focus {
                border-bottom-color: #333;
            }
        }
    }
}
