﻿.work-us-modal {

    @include media-breakpoint-down(sm) {
        padding-right: 0 !important;
    }

    &__dialog {
        margin: 0;
    }

    &__header {
        border-bottom: none;

        svg {
            width: 40px;
            height: 40px;
            stroke-width: 5px;
            stroke: $white-color;
        }
    }

    &__close {
        background: transparent;
        cursor: pointer;
        border: none;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 2;
    }

    &__input-file {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        z-index: 2;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__send-file {
        position: relative;
        margin: 30px 0;

        label {
            color: #ccc;
        }

        input {
            @include placeholder {
                color: #666 !important;
            }
        }

        div {
            position: relative;
            display: flex;
        }

        button {
            z-index: 1;
            min-height: 37px;
            min-width: 130px;
            padding: 8px 15px;
        }
    }

    &__body {
        padding: 35px 15px;
    }
}
